import * as actionTypes from './types';
import fetchData from '../services';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

export const ToggleViewState = (id) => {
    return {
        type: actionTypes.TOGGLE_VIEW_STATE,
        id: ""
    }
}

export const ModalView = (id) => {
    return {
        type: actionTypes.MODAL_VIEW,
        id: ""
    }
}

export const previewModalView = () => {
    return {
        type: actionTypes.PREVIEW_MODAL_VIEW,
    }
}

export const LogOutModalView = (id) => {
    return {
        type: actionTypes.LOGOUT_MODAL_VIEW,
        id: ""
    }
}

export const renameModel = (id) => {
    return {
        type: actionTypes.RENAME_MODAL_VIEW,
        id: ""
    }
}

export const fileEditModel = (id) => {
    return {
        type: actionTypes.FILE_EDIT_MODAL_VIEW,
        id: ""
    }
}


export const NotificationView = (id) => {
    return {
        type: actionTypes.NOTIFICATION_VIEW,
        id: ""
    }
}

export const ListModalView = (id) => {
    return (dispatch) => {
        if (id === "close") {
            dispatch({
                type: 'LIST_MODAL_VIEW',
                payload: ""
            });
        } else {
            let response = fetchData({ url: '/users/gettemplateList', method: 'POST', data: {} });
            response.then(res => {
                if (res.status === 1) {
                    dispatch({
                        type: 'LIST_MODAL_VIEW',
                        payload: res.response
                    });
                } else {

                }
            });
        }
    }
}

export const getUserDetails = (userId) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/details', method: 'POST', data: { 'userId': userId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({
                    type: 'VIEW_USER_DETAILS',
                    payload: res.response,
                });
                dispatch({
                    type: 'DOCUMENT_MANAGEMENT_STATUS',
                    payload: res.response.documentManagement,
                })
            } else if (res.status === 0) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.response, alignment: "centre" }
                });
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const logOut = (history) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/logout', method: 'POST', data: { userName: sessionStorage.getItem('userName') } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: 'AUTHENTICATION', payload: false });
                dispatch({ type: 'LOGOUT_MODAL_VIEW', payload: "" });
                sessionStorage.removeItem('isAuthenticated');
                sessionStorage.removeItem('usersessiontoken');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('userId');
                sessionStorage.removeItem('userRole');
                sessionStorage.removeItem('searchType');
                sessionStorage.removeItem('companyUserId');
                sessionStorage.removeItem('selectedFile');
                sessionStorage.removeItem('clickedFolderUid');
                sessionStorage.removeItem('clickedFolder');
                sessionStorage.removeItem('userClickedFileId');
                sessionStorage.removeItem('clickedFolderId');
                sessionStorage.removeItem('login_user_id');
                sessionStorage.removeItem('user_permission');
                sessionStorage.removeItem('isActivesubs');
                sessionStorage.removeItem('DISMIS_1');
                sessionStorage.removeItem('DISMIS');
                // dispatch({ type: actionTypes.SELECTED_FILE, payload: {} });
                dispatch({ type: 'LOGOUT', payload: true });
                localStorage.removeItem('offline_cart_subscription_details');
                dispatch({ type: actionTypes.OFF_LINE_CART_DATA, payload: [] });
                // dispatch({ type: 'LOGOUT', payload: true });
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'You have logged out successfully', alignment: "centre" }
                });
                // let response = fetchData('/user/page-lists', 'post', {});
                // dispatch({ type: actionTypes.PAGE_LOADER_PENDING })
                // response.then(res => {
                //     dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS })
                //     if (res.status === 1) {
                //         dispatch({
                //             type: actionTypes.PAGES_LIST_FOOTER,
                //             payload: res.response,
                //         });
                //     }
                // });
                // setTimeout(() => {
                //     var yourCurrentUrl = window.location.href.split('?')[0];
                //     window.history.replaceState({}, '', yourCurrentUrl);
                //     window.location.reload();
                // }, 1000);
                history.push("/login")
                // window.location.reload();
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const SelectTempate = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'SELECT_TEMPLATE',
            payload: id
        });
    }
}

export const FileNameAdd = (file) => {
    return {
        type: actionTypes.FILE_NAME_ADD,
        payload: file
    }
}

export const Clear_docs_Add_data = () => {
    return {
        type: actionTypes.CLEAR_FILE_DOCS_ADD,
        payload: {}
    }
}

export const SignatureModalView = (e, key) => {
    return {
        type: actionTypes.SIGNATURE_MODAL_VIEW,
        payload: key
    }
}

export const getCartCount = (userId) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/cart-count', method: 'POST', data: { 'userId': userId } });
        response.then(res => {
            if (res.status === 1) {
                dispatch({ type: actionTypes.CART_COUNT, payload: res.response });
            }
        });
    }
}

export const AddPdfModal = (id) => {
    return {
        type: actionTypes.PDF_MODAL_VIEW,
        payload: {}
    }
}

export const AddPdForm = (formData) => {
    return (dispatch) => {
        dispatch({
            type: 'PDF_FORM_DATA',
            payload: formData
        });
    }
}

export const EditPdfModal = (id) => {
    return {
        type: actionTypes.PDF_EDITMODAL_VIEW,
        payload: {}
    }
}


export const setSidebarExpand = (expand) => {
    return {
        type: actionTypes.SET_SIDEBAR_EXPAND,
        payload: expand
    }
}

export const deleteModalView = (id, index, type) => {
    return {
        type: actionTypes.DELETE_MODAL_VIEW,
        payload: { id, index, type }
    }
}

export const setDropdownOpen = (value) => {
    return {
        type: actionTypes.SET_HEADER_DROPDOWN,
        payload: value
    }
}

export const BottomModalView = (value) => {
    return {
        type: actionTypes.NEW_BOTTOM_MODAL,
        payload: String(value) === String(true) ? true : false
    }
}

export const Bottom_Text_With_Alignment = (value, text, alignment) => {
    return {
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: value, text, alignment: (alignment && alignment !== '') ? alignment : "centre" }
    }
}

export const restoreModalView = (data) => {
    return {
        type: actionTypes.RESTORE_MODAL_VIEW,
        payload: data ? data : {}
    }
}

export const signatureActiveTab = (tab) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.COM_SET_SIGNATURE_ACTIVETAB, payload: tab })
    }
}

export const setPencilColor = (value) => {
    return (dispatch) => {
        dispatch({ 'type': actionTypes.COM_SET_SIGNATURE_PENCIL_COLOR, 'payload': value });
    }
}
export const setTextColor = (value) => {
    return (dispatch) => {
        dispatch({ 'type': actionTypes.COM_SET_SIGNATURE_TEXT_COLOR, 'payload': value });
    }
}
export const setFontFamily = (value) => {
    return (dispatch) => {
        dispatch({ 'type': actionTypes.COM_SET_SIGNATURE_FONT_FAMILY, 'payload': value });
    }
}

export const setSignaturePreviewImage = (value) => {
    return (dispatch) => {
        dispatch({ 'type': actionTypes.COM_SET_SIGNATURE_PREVIEW_IMAGE, 'payload': value });
    }
}

export const setTextValue = (value) => {
    return (dispatch) => {
        dispatch({ 'type': actionTypes.COM_SET_SIGNATURE_TEXT_VALUE, 'payload': value });
    }
}

export const contactUs = (myProfileData) => {
    const formData = myProfileData;
    return (dispatch) => {
        let response = fetchData({ url: '/site/submit/contact-us', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING });
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Contact us submitted successfully', alignment: "centre" }
                });
            } else if (res.status === 0) {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Contact us not submitted', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                });
            }
        });
    }
}

export const getPreviewData = (userdata) => {
    return (dispatch) => {
        let response = fetchData({ url: '/user/download/sample-document', method: 'POST', data: userdata });
    }
}
export const getErrorMsg = (message) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SHOW_ERROR_MESSAGE,
            payload: { show: true, message: message }
        })
    }
}

export const closeErrorMsg = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SHOW_ERROR_MESSAGE,
            payload: { show: false, message: "" }
        })
    }
}

export const addCompanyDetails = (details) => {
    return {
        type: actionTypes.COMPANY_DETAILS_FOR_FREE_POLICY,
        payload: details
    }
}

export const downloadSampleDoc = (details, props) => {
    let formData = {
        city: details.city,
        smallcompanyLogo: details.com_companyLogo,
        companyLogo: details.companyLogo,
        companyName: details.companyName,
        country: details.country,
        email: details.email,
        line1: details.line1,
        line2: details.line2,
        name: details.name,
        phone: details.phone,
        postalCode: details.postalCode,
        state: details.state,
        website: details.website
    }
    return dispatch => {
        let response = fetchData({ url: '/download/sampledocument', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_PENDING })
        response.then(res => {
            dispatch({ type: actionTypes.BUTTON_PENDING })
            if (res.status === 1) {
                let fileUrls = res.response;
                if (fileUrls && fileUrls.length > 0) {
                    let zip = new JSZip();
                    let count = 0;
                    let zipFilename = "Sample Document.zip";
                    fileUrls.forEach(function (url, i) {
                        let filename = url.replace(/.*\//g, "");
                        JSZipUtils.getBinaryContent(url, function (err, data) {
                            if (err) {
                                throw err;
                            }
                            zip.file(filename, data, {
                                binary: true,
                                createFolders: true
                            });
                            count++;
                            if (+count === fileUrls.length) {
                                zip.generateAsync({
                                    type: "blob"
                                })
                                    .then(function (blob) {
                                        saveAs(blob, zipFilename);
                                        dispatch({
                                            type: actionTypes.BOTTOM_T_W_AL,
                                            payload: { show_hide: true, text: 'Document downloaded successfully', alignment: "centre" }
                                        });
                                        dispatch({ type: actionTypes.BUTTON_SUCCESS });
                                        dispatch({
                                            type: actionTypes.COMPANY_DETAILS_FOR_FREE_POLICY,
                                            payload: {}
                                        });
                                        props.history.push('/')
                                    });
                            }
                        });
                    });
                    dispatch({ type: actionTypes.BUTTON_SUCCESS })
                }

            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: res.message, alignment: "centre" }
                });
            }
        }).catch(error => console.log(error))
    }
}

export const documentModalView = (data) => {
    return {
        type: actionTypes.SAMPLE_DOCUMENT_MODAL,
        payload: data
    }
}
export const setImageData = (data) => {
    return {
        type: actionTypes.COMPANY_LOGO_UPLOAD,
        payload: data
    }
}
export const setCropper = (data) => {
    return {
        type: actionTypes.COMPANY_LOGO_CROP,
        payload: data
    }
}
export const getCroppedData = (data) => {
    return {
        type: actionTypes.COMPANY_LOGO_CROPDATA,
        payload: data
    }
}

export const updateDocTabToggle = (tab) => {
    return {
        type: actionTypes.CHNAGE_UPDATE_DOCUMENT_TAB,
        payload: tab ? tab : '1'
    }
}

export const getRecentUpdateDocument = (skip, limit, pageNumber, searchText = '', filter = '', countryId = '', subTypeId = '', subCategoryId = '') => {
    let data = {};
    data['skip'] = skip ? +skip : 0;
    data['limit'] = limit ? +limit : 10;
    data['searchvalue'] = searchText;
    data['countryId'] = countryId;
    data['subscriptionTypeId'] = subTypeId;
    data['subscriptionCategoryId'] = subCategoryId;

    return dispatch => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        let response = fetchData({ url: `/user/document/recently-updated`, method: 'POST', data: data });
        dispatch({ type: actionTypes.SET_TABLE_ATTRIBUTES, 'page_no': pageNumber, 'serachVal': (searchText && searchText !== '') ? searchText : "", 'skip': skip, 'itemPerPage': Number(limit) });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            if (res.status === 1) {
                dispatch({
                    type: actionTypes.RECENTLY_UPDATED_DOCUMENTS,
                    payload: res.response && res.response.length > 0 ? res.response : [],
                    count: res.total
                });
                if (res.response && res.response.length > 0) {
                    dispatch({ type: actionTypes.SHOW_NO_DATA, payload: false })
                } else {
                    dispatch({ type: actionTypes.SHOW_NO_DATA, payload: true })
                }
            } else {
                dispatch({ type: actionTypes.SHOW_NO_DATA, payload: true })
                dispatch({
                    type: actionTypes.RECENTLY_UPDATED_DOCUMENTS,
                    payload: [],
                    count: 0
                });
            }
        }).catch(error => console.log(error))
    }
}

export const getClickedId = (id) => {
    return {
        type: actionTypes.GET_CLICKED_ID,
        payload: id ? id : ''
    }
}
export const manualBottomModalToggle = (condition, text, alignment, path) => {
    return {
        type: actionTypes.MANUAL_BOTTOM_MODAL,
        payload: { show_hide: condition, text: text, alignment: alignment, path: path }
    }
}

export const toggleOfferModal = (val) => ({ type: actionTypes.OFFER_MODAL, payload: val ? val : false })

export const getSlug = (arr) => {
    return async (dispatch) => {
        const data = { array: JSON.stringify(arr) };
        const res = await fetchData({ url: '/get/offer/slug', method: 'GET', params: data });
        if (res.status === 1) {
            dispatch({ type: actionTypes.OFFER_SLUG, payload: res.response })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
};
export const getPolicyViewData = (fileId, userId) => {
    let formData = {
        id: fileId ? fileId : '',
        userId: userId ? userId : ''
    }
    return (dispatch) => {
        let response = fetchData({ url: '/user/view/policies', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                let content = res.response.headerContent + res.response.innerContent + res.response.footerContent;
                dispatch({ type: 'PUBLIC_POLICY_CONTENT', payload: res.response.innerContent });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const downloadPublicPolicy = (fileId, userId, filetype = '', sendtype = '') => {
    let formData = {
        fileId: fileId ? fileId : '',
        userId: userId ? userId : '',
    }, url = '/user/policies/viewpdf';
    return (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        if (filetype === 'open') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF File', alignment: "bottom" }
            });
        }
        if (filetype === 'filepdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF File', alignment: "bottom" }
            });
        }
        if (filetype === 'word') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating Word File', alignment: "bottom" }
            });
            url = '/user/document/download';
            // url = '/user/download-word';
            // response = fetchData('/user/download-word', 'post', formData);
        } else if (filetype === 'pdf') {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Generating PDF File', alignment: "bottom" }
            });
            url = '/user/download-pdf-url';
            // response = fetchData('/user/download-pdf-url', 'post', formData);
        }
        let response = fetchData({ url: url, method: 'POST', data: formData });
        response.then(res => {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            if (res.status === 1) {
                if (filetype === 'filepdf' || filetype === 'file') {
                    // let oReq = new XMLHttpRequest();
                    // let URLToPDF = res.response;
                    // let url = window.location.origin + new URL(URLToPDF).pathname;
                    // let filename = (item.name && item.name !== '') ? item.name : item.filename;
                    // oReq.open("GET", url, true);
                    // oReq.setRequestHeader('Access-Control-Allow-Origin', '*');
                    // oReq.responseType = "blob";
                    // oReq.onload = function () {
                    //     var file = new Blob([oReq.response], {
                    //         type: 'application/pdf'
                    //     });
                    //     saveAs(file, filename + ".pdf");
                    // };
                    // oReq.send();
                } else if (filetype === 'pdf') {
                    let oReq = new XMLHttpRequest();
                    let URLToPDF = res.response.content;
                    let url = window.location.origin + new URL(URLToPDF).pathname;
                    let filename = (res.response.name && res.response.name !== '') ? res.response.name : res.response.filename;
                    oReq.open("GET", url, true);
                    oReq.responseType = "blob";
                    oReq.onload = function () {
                        var file = new Blob([oReq.response], {
                            type: 'application/pdf'
                        });
                        saveAs(file, filename + ".pdf");
                    };
                    oReq.send();
                } else {
                    if (String(sendtype) === String('direct')) {
                        window.open(res.response, '_blank');
                    } else {
                        // const tabOpen = window.open("about:blank", "newtab");
                        // tabOpen.location = res.response;
                        window.open(res.response, "newtab");
                    }
                }
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const downloadPolicyAsPDF = (userId, fileId) => async (dispatch) => {
    dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
    dispatch({
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: true, text: 'Generating PDF File', alignment: "bottom" }
    });
    let result = await fetchData({
        url: '/user/policies/viewpdf',
        method: 'POSt',
        data: { fileId, userId }
    });
    if (result && result.status === 1) {
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: false, text: '', alignment: "centre" }
        });
        window.open(result.response, "newtab");
    } else {
        dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
        });
    }

}
export const previewVersionModalView = () => {
    return {
        type: actionTypes.PREVIEW_VERSION_MODAL_VIEW,
    }
}

export const getViewPolicyContent = (id) => {
    let formData = {
        id: id ? id : '',
        userId: sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : ''
    }
    return (dispatch) => {
        dispatch({ type: 'UPDATE_DOCUMENT_CONTENT', payload: { 'fileContentNew': '' } });
        let response = fetchData({ url: '/user/view/policies', method: 'POST', data: formData });
        response.then(res => {
            if (res.status === 1) {
                let content = res.response.headerContent + res.response.innerContent + res.response.footerContent;
                dispatch({ type: 'UPDATE_DOCUMENT_CONTENT', payload: { 'fileContentNew': content } });
            } else {
                if (typeof res.response === 'object') {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: 'Something Went Wrong.', alignment: "centre" }
                    });
                } else {
                    dispatch({
                        type: actionTypes.BOTTOM_T_W_AL,
                        payload: { show_hide: true, text: res.response, alignment: "centre" }
                    });
                }
            }
        });
    }
}

export const mobileModalFunc = () => ({ type: actionTypes.MOBILE_VIEW_MODAL })
export const subExpiryNotifyToggle = (key = false) => ({ type: actionTypes.SUBSCRIPTION_EXPIRY_NOTIFY_MODAL, payload: key })
export const restoreFileModalView = (data) => {
    return {
        type: actionTypes.RESTORE_FILE_MODAL_VIEW,
        payload: data ? data : {}
    }
}

export const deletePModalView = (id, index, url, returnurl, collection) => {
    return {
        type: actionTypes.PDELETE_MODAL_VIEW,
        payload: { id, index, url, returnurl, collection: (collection && collection !== '') ? collection : "" }
    }
}

export const restorePModalView = (id, index, collection) => {
    return {
        type: actionTypes.PRESTORE_MODAL_VIEW,
        payload: { id, index, collection: (collection && collection !== '') ? collection : "" }
    }
}

export const lockDocForUserModalView = (item) => {
    return {
        type: actionTypes.LOCK_DOC_USER_MODAL_VIEW,
        payload: item ? item : {}
    }
}